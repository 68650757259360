@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Raleway";
  font-weight: 300;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #dbdbdb #f3f3f3;
}

::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f3f3f3;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #dbdbdb;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid #dbdbdb;
  /* creates padding around scroll thumb */
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex: 1;
  flex-direction: column;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.footer {
  position: fixed;
  display: flex;
  width: calc(100% - 200px);
  bottom: 0;
  padding: 10px;
  height: 50px;
  left: 200px;
  background-color: #f5f3f5;
  align-items: center;
  box-shadow: 2px 2px 2px rgb(0 0 0/ 20%);
}

.footer_left_position {
  left: 80px !important;
  width: calc(100% - 80px) !important;
}

/* LOGIN STYLES */
.login {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_container {
  border: solid 15px #1f3c9b;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 650px;
  border-radius: 10px;
  padding: 35px;
  text-align: center;
}

.login_img {
  width: 300px;
}

.login_container input {
  margin-top: 20px;
}

.login_container button {
  margin-top: 40px;
  align-items: center;
  display: flex;
  line-height: 30px;
}

.login_container button svg {
  margin-right: 30px;
}

/* APP CONTAINER STYLES */
.topNav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 50px;
  background: #f5f3f5;
  padding: 0 20px;
  box-shadow: 2px 2px 2px rgb(0 0 0/ 20%);
}

.account_icon {
  color: white !important;
  font-size: 99px;
}

.leftNav {
  position: fixed;
  top: 51px;
  left: 0;
  bottom: 0;
  width: 211px;
  padding-top: 25px;
  background: rgba(245, 243, 245, 0.7);
  z-index: 300;
}

.leftNav:hover .navigateBlock {
  display: block;
}

.leftNav_width {
  width: 80px !important;
}

.leftNav_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

.logo_container {
  text-align: center;
}

.top_nav_img {
  width: 60px !important;
  height: 60px;
  margin-top: -5px;
}

.logo_container img {
  width: 180px;
}

.leftNav_content a {
  flex: 1;
  margin: 2px 0;
  font-size: 14px;
  text-decoration: none;
  color: rgb(66, 66, 66);
  padding: 5px 10px 5px 10px;
  line-height: 0px;
  align-items: center;
  display: flex;
  line-height: 30px;
  color: #302d76;
  font-weight: bold;
}

.leftNav_content .a_open {
  padding: 5px 20px 5px 20px;
  transition: padding 0.5s;
}

.leftNav_content .a_close {
  display: flex;
  flex-direction: column;
  line-height: 21px;
  text-align: center;
  font-size: 12px !important;
}

.leftNav_content a:hover,
.active {
  color: #d56664 !important;
  background: rgb(213 102 100 / 3%) !important;
  font-weight: bold !important;
}

.leftNav_content a svg {
  margin-right: 10px;
}

.content {
  position: fixed;
  top: 50px;
  left: 209px;
  right: 0;
  bottom: 0;
  padding: 10px;
  overflow-y: auto;
}

.content_left_position {
  left: 81px;
}

.fullContent {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.MuiDrawer-paperAnchorLeft {
  padding-top: 30px;
}

.chart {
  width: 75%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  border-radius: 5px;
}

/* MEDIA TAB */
.mediaTab {
  display: flex;
  flex-direction: row;
}

.mediaTab_asset_lists {
  display: flex;
  flex-direction: column;
  width: 300px;
  overflow-y: scroll;
}

.media_asset_category {
  margin: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #3f51b5;
}

.media_row {
  display: flex;
  flex-direction: column;
  border: 1px #cecece solid;
  padding: 7px;
  margin: 0 0 0 15px;
  font-size: 11px;
}

.media_row_header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.media_row_header_title {
  flex: 1;
  font-size: 12px;
  font-weight: 400;
}

.media_row_header button {
  padding: 0;
  margin: 2px;
}

.media_row_desc {
  font-size: 10px;
  font-style: italic;
}

.mediaAsset {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.media_edit {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  font-size: 10px;
  padding: 30px;
}

.inputForm {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 30px;
}

.inputForm button {
  margin-top: 15px;
}

.preview {
  border: 1 px solid black;
  border-radius: 3px;
  flex: 2;
  text-align: center;
  vertical-align: middle;
  padding: 30px;
}

.preview img,
.preview video {
  max-height: 200px;
}

.questionDiv {
  display: flex;
  flex-direction: row;
  margin: 10px 5px 10px 11px;
  font-size: 16px;
}

.questionInfoDiv {
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 60%;
  margin: 10px auto 20px 60px;
  padding-left: 50px;
  font-size: 22px;
}

.info {
  position: relative;
  top: -3px;
  left: -7px;
}

.info svg {
  background-color: white;
}

.hiddenInfo {
  display: none;
  position: relative;
  z-index: 99;
  top: 15;
  right: -40px;
  background-color: #cfd6f8;
  border: solid 1px black;
  border-radius: 5px;
  padding: 10px;
  width: 600px;
}

.questionDiv input {
  margin-right: 20px;
}

.questionLabel {
  font-size: 22px;
}

.questionInputDiv {
  display: flex;
  flex-direction: row;
}

.addMedia_progress {
  margin: 50px 0 0 50px;
}

.sim_header {
  margin-bottom: 25px;
  text-align: right;
}

.sim_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px black solid;
  padding-bottom: 15px;
  margin-bottom: 15px;
  width: 100%;
}

.sim_row_details {
  flex: 1;
}

.sim_row_actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sim_row_title {
  margin: 0;
  padding: 5px;
  color: #1f3c9b;
  font-weight: bold;
}

.sim_row_time {
  margin-left: 15px;
  font-weight: 300;
  font-style: italic;
}

.sim_row_description {
  font-size: 12px;
  margin: 0;
}

.simBuilderForm {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 10px 30px;
}

.simBuilderForm .MuiTextField-root {
  margin: 5px;
}

.centered_form_row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.right {
  justify-content: flex-end;
}

.simBuilderForm button {
  margin: 15px;
}

.builderTabs .MuiTabs-root {
  min-height: 0px;
}

.builderTabs .MuiButtonBase-root {
  min-height: 0;
}

.builderTabs .MuiTab-root {
  padding: 10px;
}

.builderTabs .MuiTab-wrapper {
  font-size: 12px;
}

.actorTab {
  display: flex;
  flex-direction: row;
}

.simBuilder_actorList {
  padding-top: 10px;
}

.simBuilder_actor_row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px grey solid;
  background: #f0efef;
  margin: 0;
  padding: 2px 0 2px 15px;
}

.simBuilder_actor_row .MuiIconButton-root {
  margin: 10px 2px 10px 5px;
  padding: 0;
}

.flexCol {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.simBuilder_actor_row .name {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.simBuilder_actor_row .title {
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  margin: 0;
}

.actor_edit {
  flex: 1;
}

.actorEditForm {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 10px auto 10px auto;
}

.actorEditForm .MuiTextField-root {
  margin: 5px;
}

.actorEditForm button {
  margin: 15px 25% 0 25%;
}

.actor_avatar {
  width: 200px;
  height: 200px;
  margin: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.track {
  height: 94%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.track_nav {
  border-right: 1px solid #cecece;
  padding: 15px 0 0 0;
  margin: 0;
  position: relative;
  min-width: 230px !important;
}

.track_nav.track_nav_collapse {
  max-width: 15%;
  min-width: 190px !important;
  word-break: break-word;
}

.track_nav:hover .navigateBlockVertical {
  display: block;
}

.track_list_item {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin: 0;
  padding: 0;
  flex: 1;
  border-bottom: 1px solid #cecece;
  cursor: move;
}

.track_list_item_title {
  padding: 10px 5px 10px 5px;
  flex: 1;
}

.track_list_item:hover {
  background-color: #e5eef3;
}

.track_list_item.indent {
  margin-left: 15px;
}

.track_list_item .title {
  flex: 1;
}

.track_list_item .MuiIconButton-root {
  margin: 0;
  padding: 0px;
}

.selected {
  background: yellow;
}

.track_content {
  height: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 650px;
}

.track_preview {
  margin-right: 4px;
  text-align: center;
}

.track_preview_inner {
  max-height: 30vh;
  overflow: auto;
  margin-bottom: 10px;
}

.track_preview h5 {
  width: 100%;
  margin: 0;
  padding: 5px 0 5px 15px;
  text-align: left;
}

.track_preview video {
  width: 40%;
  max-height: 400px;
  margin: 15px auto 15px auto;
}

.track_settings {
  padding-top: 10px;
  margin-right: 4px;
  overflow: auto;
  overflow-x: hidden;
  flex: 1;
  border-top: 1px solid #cecece;
  margin-bottom: 10px;
}

.track_settings h5 {
  background: #cecece;
  width: 100%;
  padding: 3px 0px 3px 25px;
  font-size: inherit;
  margin: 0;
}

.mediaTab {
  padding: 10px;
  margin-top: 10px;
}

.track_preview h1 {
  font-size: 22px;
}

.track_preview p {
  font-size: 22px;
  text-align: left;
  margin: 15px;
}

.track_preview video {
  height: 100%;
  width: 100%;
}

.track_preview .MuiTextField-root {
  margin: 30px auto 30px auto;
  width: 75%;
}

.track_preview input,
.track_preview textarea,
.controlPanel select {
  font-size: 11px;
}

.player_inner_content_with_image {
  width: 60%;
  text-align: left;
  min-height: 200px;
  font-size: 22px;
}

.player_inner_content {
  text-align: left;
  min-height: 200px;
  font-size: 22px;
}

.email {
  width: 80%;
  margin: 25px auto 0 auto;
  text-align: left;
  min-height: 200px;
  min-width: 400px;
  font-size: 22px;
  padding-left: 15px;
}

.emailHeader {
  display: flex;
  flex-direction: row;
  margin: auto;
  font-size: 22px;
}

.emailHeader .emailToFrom {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.emailHeader .emailToFrom p {
  margin: 0px;
  font-size: 22px !important;
}

.emailHeaderButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.emailHeaderButtons p {
  flex: 1;
  margin: 0;
  padding-right: 15px;
}

.heading {
  background-color: #cecece;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.expand {
  display: block;
}

.collapse {
  display: none;
}

.inline {
  display: inline-block;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 60px;
}

.expand_all {
  display: flex;
  gap: 8px;
  width: max-content;
  margin-left: auto;
  font-size: 11px;
  font-weight: 600;
  align-items: center;
  padding: 2px 0px;
  cursor: context-menu;
  z-index: 100;
}

.controlPanel {
  display: flex;
  flex-direction: column;
}

.controlPanel .MuiTextField-root {
  width: 100%;
}

.controlPanel input,
.controlPanel textarea {
  font-size: 11px;
}

.controlPanel button {
  margin: 10px;
}

.controlPanel .MuiFormLabel-filled {
  background: white;
  padding: 3px;
}

.controlPanel .MuiFormControl-root {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  width: 100%;
}

.controlPanel .MuiInputLabel-outlined {
  padding-left: 10px;
}

.controlPanel h3 {
  background: #cecece;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 0 3px 25px;
  margin: 0;
  width: 100%;
}

.controlPanel .optionForm {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.controlPanel p.listItem,
.variable_edit p.listItem {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  line-height: 20px;
  width: 90%;
  margin: auto;
  border-bottom: 1px solid #cecece;
}

.controlPanel > .expand > p.listItem span,
.variable_edit > p.listItem span {
  flex: 1;
}

.controlPanel .MuiIconButton-root,
.variable_edit .MuiIconButton-root {
  margin: 0;
  padding: 3px;
}

.visibility_setting_row {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.visibility_setting_row_item {
  display: flex;
  flex-direction: row;
  font-size: 10px;
  justify-content: space-evenly;
  margin: 0;
  padding: 3px;
  border-bottom: 1px solid #cecece;
}

.visibility_setting_row_item .MuiIconButton-root {
  margin: 0;
  padding: 3px;
}

.formActions {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  right: 8px;
}

.dialogListItem:hover {
  background: lime;
}

.variableTab {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.variableTab_list {
  width: 400px;
}

.variableTab_list_item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px grey solid;
  background: #f0efef;
  margin: 0;
  padding: 2px 0 2px 15px;
}

.flexCol {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.variableTab_list_item .name {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.variableTab_list_item .desc {
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  margin: 0;
}

.variableTab_list_item .detail {
  font-weight: 500;
  font-size: 10px;
  margin: 3px 0 0 0;
  padding: 0;
}

.variableTab_list_item .MuiIconButton-root {
  margin: 10px 2px 10px 5px;
  padding: 0;
}

.variable_edit {
  flex: 1;
}

.variable_edit_form {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 20px auto 10px auto;
}

.variable_edit_form .MuiTextField-root {
  margin: 5px;
}

.variable_edit_form button {
  margin: 15px 25% 0 25%;
}

.timerLabel {
  font-size: 1.5rem;
  font-weight: 300;
}

.timerOutline {
  border: solid 2px #cecece;
  height: 12px;
  border-radius: 5px;
  overflow: hidden;
}

.timerFill {
  background: chartreuse;
  border-radius: 5px;
  display: block;
  height: 12px;
  font-size: 8px;
  text-align: center;
  color: black;
}

.externalPlayer {
  padding: 20px;
}

.hidden {
  display: none;
}

.back {
  padding-left: 15px;
}

.loader {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-size: contain;
  justify-content: center;
}

.loadMessage {
  color: grey;
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.loaderForModels {
  position: absolute;
  color: grey;
  display: flex;
  width: 100%;
  height: 100%;
  margin: -20px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.player {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-position: "center";
  background-repeat: "no-repeat";
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 25px;
  height: 96vh;
  overflow: auto;
  margin-top: 28px;
  margin-left: 4px;
}

.player_top_nav {
  padding: 14px 20px 10px 100px;
  border-top: 3px solid #cecece;
  border-top-left-radius: 20px;
  border-left: 3px solid #cecece;
  border-right: 3px solid #cecece;
  height: 10%;
  position: fixed;
  width: 100%;
  top: 14px;
  overflow: hidden;
  margin-top: 15px;
}

.player_content {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px 20px 20px 0px;
  position: relative;
  border-left: 3px solid #cecece;
  border-right: 3px solid #cecece;
}

.player_content_without_ad {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px 0px 20px 0px;
  position: relative;
  border-left: 3px solid #cecece;
  border-right: 3px solid #cecece;
}

.player_content_main {
  flex: 1;
  overflow: auto;
  margin-top: 86px;
  margin-left: 60px;
  padding: 20px;
  height: 76vh;
  margin-right: -46px;
}

.player_action_row {
  background-color: #cecece;
  border: 1px solid #cecece;
  border-bottom-right-radius: 30px;
  padding: 5px 25px 5px 25px;
  background-image: url("../assets/images/logo_outlined.png");
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: bottom 10px right 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.player .MuiTextField-root {
  margin: 30px auto 30px auto;
  width: 75%;
}

.player input,
.player textarea {
  font-size: 22px;
}

.phone_toggles {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.phone_track {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.phone_track p {
  margin: 30px;
  font-size: 22px !important;
}

.phone {
  display: flex;
  background-size: cover;
  height: 400px;
  width: 200px;
  min-width: 200px;
  justify-content: center;
}

.phone_contact {
  font-weight: 600;
  font-size: 22px;
  color: white;
  display: flex;
  margin: 80px 10px 0 20px;
  text-align: center;
}

.phone_track_content {
  display: flex;
  flex-direction: column;
}

.phone_track_content audio {
  margin-bottom: 20px;
}

.playerActionRow {
  border: 2px;
}

.next_button_row {
  justify-self: end;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 30px 0 10px;
}

.player .video_player {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  background-color: black;
}

.video_ele {
  height: 60vh;
}

.player h1 {
  font-size: 22px;
  margin: 15px 0px 5px 0px;
}

.player h2 {
  font-size: 22px;
  color: darkslategrey;
  margin: 0 0 5px 0;
}

.player p {
  font-size: 22px !important;
}

.texttrack_content {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.texttrack_content div {
  flex: 1;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  /* margin: 10px 30px 30px 30px; */
  /* min-height: 500px; */
  margin-left: 10px;
  min-width: 500px;
  width: 500px;
}

.pdf_display {
  height: 87%;
}

.popup_background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(104, 104, 104, 0.8);
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1050;
  border: 5px solid #333333;
  background-color: white;
  max-width: 600px;
  width: auto;
  height: 500px;
  margin: 1.75rem auto;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  filter: drop-shadow(30px 10px 4px #464646);
}

.popup_close {
  display: flex;
  background-color: #cecece;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  width: 100%;
}

.popup_content {
  padding: 20px;
  overflow: auto;
  font-size: 22px;
}

.popup_content > .welcome_text > p {
  font-size: 14px !important;
}

.popup_content > .actor_avatar {
  width: 150px;
  height: 150px;
}

.call_log_item {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #cecece;
}

.call_log_item .from {
  flex: 1;
  padding-left: 15px;
}

.call_log_item .MuiSvgIcon-root {
  color: black;
}

.sliders {
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  text-align: center;
}

.sliders h5 {
  margin-top: 20px;
  font-size: 22px;
}

.sliders p {
  text-align: left;
  width: 60%;
  font-size: 22px;
  margin: 5px 0 40px 0;
  color: #696969;
}

.report {
  padding: 5px;
  font-size: 22px;
  word-break: break-word;
}

.report_item {
  border: solid 2px #cecece;
  border-radius: 5px;
  padding: 0px 20px 20px 20px;
  margin-bottom: 10px;
}

.report_item h2 {
  color: #1f3c9b;
  margin: 20px 0 0 0;
}

.report_item h3 {
  color: #686868;
  font-size: 22px;
  margin: 0 0 5px 0;
  padding: 0;
}

.report_item p {
  font-size: 22px;
  padding: 0 0 0 25px;
  margin: 0;
}

.report_item h3 {
  margin: 20px 0 0 0;
  text-decoration: underline;
}

.report_item ul {
  padding: 0 0 15px 75px;
  margin: 0;
  font-size: 22px;
}

.feedback_choice {
  font-weight: 400;
  font-size: 14px;
  margin-left: 15px;
}

.emailContent {
  display: flex;
  flex-direction: row;
}

.inboxGraphic img {
  max-width: 100%;
  height: auto;
}

.smsInbox {
  min-width: 400px;
  min-height: 400px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 10px;
}

@media print {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  div {
    overflow: visible !important;
  }

  .player {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  body,
  .main,
  .player_top_nav,
  .player_content,
  .player_content_main {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.editorWrapper {
  padding: 10px;
  width: 99%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  border: 1px solid #cecece;
  border-radius: 5px;
}

.editorTitle {
  position: relative;
  z-index: 9999;
  top: -22px;
  font-size: 13px;
  color: #808080;
  padding: 0 8px 0 8px;
  background-color: white;
}

.welcome_title {
  font-size: 24px !important;
}

.welcome_text {
  font-size: 22px !important;
}

.welcome_text p {
  font-size: 1.4em !important;
}

.trackDetailReportRow ul {
  margin: 5px 0 0 7px;
  padding-left: 0px;
}

.trackDetailReportRow ul li {
  font-size: 12px;
  margin: 5px 0 0 0px;
}

.trackDetailReportRow ul li ul li ul {
  list-style: none;
}

#reportDialogHeader {
  background-color: #646464;
  color: white;
}

.flexSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.report_print_icon {
  color: white !important;
  font-size: 99px;
  text-align: right;
}

.phone_audio {
  display: flex;
  flex-direction: column;
}

.spacer {
  display: inline-block;
  height: 20px;
  width: 24px;
}

.report_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.report_feedback {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  top: 11px;
  right: 13px;
  position: absolute;
}

.report_feedback_with_ad {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  top: -18px;
  right: 13px;
  position: absolute;
}

.resetModal {
  position: fixed;
  /* or absolute */
  top: 50%;
  left: 50%;
  height: 100px;
  width: 450px;
  margin-top: -50px;
  margin-left: -225px;
  z-index: 999;
  border: solid 3px blue;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
}

/*...Loader overlay...*/
.overlay {
  position: fixed;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.overlayForModels {
  position: fixed;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

.sim_loader {
  display: flex;
  flex: 1;
  flex-direction: row;
  background-size: contain;
  justify-content: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bg-heading {
  background-color: #e2e2e2;
}

.bg-heading h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.MuiInputBase-root {
  line-height: unset !important;
}

.table td,
.table th {
  padding: 0.75rem !important;
}

.MuiBox-root {
  display: inline !important;
  align-items: center;
}

.character-file-upload {
  width: 175px;
}

.media-file-upload {
  width: 175px;
}

.nav-bar {
  display: flex;
  width: 100%;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  margin-left: 0 !important;
}

.help-icon-header {
  margin-right: 5px;
  margin-top: 11px;
  margin-bottom: 10px;
  color: rgb(99, 95, 95) !important;
}

/* acatar menu item */
.MuiListItem-root.Mui-selected {
  background-color: white !important;
}

.MuiListItem-root.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.text-error {
  color: red !important;
}

.loadMessage h3 {
  display: block !important;
  font-size: 1.17em !important;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  font-weight: bold !important;
  background: none !important;
}

.navigateBlock {
  width: 20px;
  height: 20px;
  background-color: white;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  word-break: initial;
  cursor: pointer;
  color: #1d1d1d;
  position: relative;
}

.navigateBlockVertical {
  position: absolute;
  display: none;
  right: 0;
  transform: translate(50%, -50%);
  z-index: 400;
}

.navigateBlock:hover::after {
  content: attr(data-title);
  position: absolute;
  color: #fff;
  padding: 4px 8px;
  font-size: 0.625rem;
  max-width: 300px;
  word-wrap: break-word;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.4em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
  transform: translate(-68px, 0px);
  z-index: 1000;
}

.navigateBlockVertical:hover::after {
  transform: translate(30px, 0);
}

.navigateBlock:hover {
  background-color: #d56664;
  color: white;
}

.top_position {
  top: 55px;
}

.block-width {
  max-width: 30%;
  min-width: 200px;
  word-break: break-word;
}

.ifDrag {
  opacity: 0;
}

.ifNotDrag {
  opacity: 1;
}

.tracklist {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-bottom: 20px;
  height: 83%;
  overflow: auto;
}

.color-blue {
  color: #302d76;
}

.leftNav_content .a_close .margin-right {
  margin-right: 0 !important;
}

.cursor-move {
  cursor: move;
}

.code-block {
  font-family: Consolas, "courier new";
  color: crimson;
  background-color: #f1f1f1;
  padding: 1px;
  font-size: 90%;
}

.list-text-color {
  color: blue !important;
  font-weight: bold !important;
}

.trueVariables {
  color: green !important;
}

.falseVariables {
  color: #d46866 !important;
}

.actualVariables {
  color: black !important;
}

.warning-icon {
  margin-bottom: 5px;
  margin-left: 5px;
  color: red !important;
}

.warning-text {
  color: red;
  font-weight: bold;
}

.folder-row:hover {
  cursor: pointer !important;
}

.track_preview_expand {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 25px;
  margin-right: 4px;
}

.track_preview_expand h1 {
  margin: 0 !important;
  margin-top: 3px !important;
  font-size: 22px !important;
}

.preview_fullscreen_button {
  font-size: 13px !important;
  text-transform: initial !important;
  background-color: #539e53 !important;
  letter-spacing: 1.4px !important;
  color: white !important;
}

.track_preview_inner_fullscreen {
  overflow: inherit !important;
}

.track_preview_inner_fullscreen #pdf object {
  height: 50vh;
  margin-bottom: 10px;
}

.track_preview_inner_fullscreen video {
  width: unset !important;
  height: unset !important;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  right: -100px !important;
}

.datePicker {
  margin-top: 10px;
  margin-left: -7px;
  margin-right: -8px;
}

.file_label {
  font-size: 12px;
}

.no_file_chosen {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.54);
}

.error {
  color: #f44336;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.break-word {
  word-break: break-word;
}

.margin-right-n8 {
  margin-right: -8px !important;
}

.MuiListItemText-root {
  font-size: 22px;
}

.MuiSlider-markLabel {
  font-size: 22px !important;
}

.select_track_input_height {
  width: 20px;
  height: 20px;
  border-radius: 0.25rem;
}

.summary_main {
  color: green;
  font-weight: bold;
}

.person-info-instructions {
  font-size: 15px !important;
  color: rgb(104, 104, 104);
}

.report-item-summary {
  border: solid 2px #cecece;
  border-radius: 5px;
  padding: 15px;
  min-width: 100px;
  display: inline-block;
  margin: 10px;
}

.personal-info {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}

.un-available-options {
  color: #bbbbbb;
}

.tooltip-font-size {
  font-size: 22px !important;
}

.input-group-text {
  color: #868e96 !important;
  background: #f8f9fa;
  border-color: #dee2e6;
  border-radius: 0;
}

.table_size_per_page_text {
  background: none !important;
  border: medium none !important;
  padding: 0px;
}

.table_header_color {
  color: #2196f3;
  cursor: pointer;
}

th.sortable[aria-label~="sort"] {
  display: flex;
  justify-content: space-between;
}

.sort_symbol > img {
  width: 16px;
}

.session-popup {
  height: 200px;
  font-size: 22px;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-weight: 700;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  top: 60px;
  text-align: center;
}

.prompt-bg-color {
  background-color: rgba(240, 240, 240, 0.65);
  display: unset !important;
}

.play-button-width {
  width: 50px;
  cursor: pointer;
}

#pdf-report {
  display: none;
}

.font-change {
  font-family: "Times New Roman", Times, serif;
}

.personal-info-pdf {
  margin-top: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 12px;
}

img {
  font-size: 12px;
  color: black;
}

.player-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}

.additional-data {
  margin: 10px;
  width: 25%;
  height: 98vh;
  position: sticky;
  top: 30px;
  align-self: self-start;
  overflow: auto;
}

.player-height {
  height: 100%;
}

.buttonStyle {
  margin-top: 5px;
  margin-bottom: 15px;
  margin-right: 10px;
}

.loaderWrapper {
  position: "fixed";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1500;
  color: blue !important;
}

.imageStyle {
  height: 150px;
  width: 200px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.label {
  border: 1px solid #fff;
  padding: 10px;
  display: block;
  position: relative;
  margin: 10px;
  cursor: pointer;
}

.imageDivLayout {
  display: inline-block;
  margin: 15px;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
}

.box {
  border: 1px solid grey;
  border-radius: 8px;
  max-width: 100%;
  margin: 10px;
}

.outLineInput {
  margin-top: 10px;
  margin-bottom: 10px;
}

.textMargin {
  margin-left: 15px;
}

.gridImage {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.grid {
  margin: 15px;
  border: 1px solid grey;
  padding: 5px;
  border-radius: 5px;
}

.textPrompt {
  border: 1px solid grey;
  border-radius: 8px;
  max-width: 100%;
  margin: 10px;
}

.align-btns {
  display: flex;
  justify-content: flex-end;
  height: 35px;
  overflow: auto;
  position: fixed;
  top: 96px;
  right: 20px;
}

.align-btns-with-ad {
  display: flex;
  justify-content: flex-end;
  margin-left: -87px;
  height: 35px;
  overflow: auto;
  position: fixed;
  top: 108px;
  right: 28%;
}

.next-btn-margin {
  margin-left: 15px !important;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eeeeee;
  border: 1px solid #cecece;
  border-radius: 11px;
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: bottom 10px right 20px;
  align-items: center;
  position: fixed;
  height: 93%;
}

.sidebar_with_ad {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eeeeee;
  border: 1px solid #cecece;
  border-radius: 11px;
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: bottom 10px right 20px;
  align-items: center;
  position: fixed;
  height: 93%;
  top: 60px;
}

.submit-btn-align {
  position: fixed !important;
  top: 95px !important;
  right: 26px;
}

.submit-btn-align-unpublish {
  position: fixed !important;
  top: 95px !important;
  right: 102px;
}

.btn-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 608px;
}

.player_content_main_end_track {
  flex: 1 1;
  overflow: auto;
  margin-left: 60px;
  padding: 20px;
  height: 82vh;
  margin-top: 3%;
  margin-right: -26px;
}

.player_content_main_end_track_with_ad {
  flex: 1 1;
  overflow: auto;
  margin-left: 60px;
  padding: 20px;
  height: 84vh;
  margin-top: 10px;
  margin-right: -45px;
}

.player_content_main_publish {
  flex: 1 1;
  overflow: auto;
  margin-top: 82px;
  margin-left: 60px;
  padding: 20px;
  height: 78vh;
  margin-right: -47px;
}

.player_top_nav_with_ad {
  padding: 14px 20px 10px 100px;
  border-top: 3px solid #cecece;
  border-top-left-radius: 20px;
  border-left: 3px solid #cecece;
  border-right: 3px solid #cecece;
  height: 10%;
  position: sticky;
  width: 100%;
  top: 14px;
  margin-top: 15px;
}

.position_fixed {
  position: fixed;
}

.text-right-freetext {
  text-align: right !important;
}

.player_content_main_publish_wid_wlcm_track {
  flex: 1 1;
  overflow: auto;
  margin-top: 82px;
  margin-left: 60px;
  padding: 20px;
  height: 67vh;
  margin-right: -47px;
}

.question-track {
  display: flex !important;
  justify-content: space-between !important;
}

.liker_scale {
  width: 85vw;
  margin-left: 54px;
}

.liker_scale_with_img {
  width: 49vw;
  margin-left: 48px;
  margin-right: 35px;
}

.additional-data .ck.ck-editor__main > .ck-editor__editable,
.additional-data figure,
.additional-data .image-inline {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.additional-data figure.image > a:after {
  background: none !important;
}

.ck.ck-toolbar {
  background-color: rgb(250, 250, 250) !important;
}

.font-weight {
  font-weight: bold;
}

.notes {
  font-size: 14px !important ;
  color: red;
}

.notes-margin {
  margin-top: -6px !important;
}

.text-red {
  color: red;
}

.required:after {
  content: " *";
  color: red;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.checkbox-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkbox-label {
  cursor: default !important;
}

.topNav_public {
  height: 50px;
  background: #f5f3f5;
  padding: 0 20px;
  box-shadow: 2px 2px 2px rgb(0 0 0/ 20%);
}

.dialog-content-font {
  font-size: 1rem;
  font-weight: normal;
}

.response-grey {
  background-color: #e4e2e2;
  border-radius: 5px;
  display: inline;
}

.white_space_preserve_with_word_wrap {
  white-space-collapse: preserve;
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.logo {
  justify-content: center; /* Centers the logo */
  align-items: center;
  width: 50%;
  max-width: 100%;
}